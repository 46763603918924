"use client"

import "remixicon/fonts/remixicon.css"
import { UserProvider } from "@auth0/nextjs-auth0/client"
import { NextUIProvider } from "@nextui-org/system"
import { useRouter } from "next/navigation"
import { ThemeProvider as NextThemesProvider } from "next-themes"
import { ThemeProviderProps } from "next-themes/dist/types"
import * as React from "react"

export interface ProvidersProps {
  children: React.ReactNode
  themeProps?: ThemeProviderProps
}

export const Providers = ({ children, themeProps }: ProvidersProps) => {
  const router = useRouter()

  return (
    <NextUIProvider navigate={router.push}>
      <NextThemesProvider {...themeProps}>
        <UserProvider>{children}</UserProvider>
      </NextThemesProvider>
    </NextUIProvider>
  )
}
